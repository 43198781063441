import { setCookie, deleteCookie } from 'cookies-next';

const WISHLIST_COOKIE_LIFETIME = 60 * 60 * 24 * 30; // 30 days in seconds
const WISHLIST_COOKIE_KEY = 'wishlist';

export const setWishlistUuidCookie = (wishlistUuid: string): void => {
    setCookie(WISHLIST_COOKIE_KEY, wishlistUuid, {
        maxAge: WISHLIST_COOKIE_LIFETIME,
        path: '/',
        sameSite: 'strict',
    });
};

export const cleanWishlistCookie = (): void => {
    setCookie(WISHLIST_COOKIE_KEY, '', {
        maxAge: 1,
        path: '/',
        sameSite: 'strict',
    });
    deleteCookie(WISHLIST_COOKIE_KEY, { path: '/' });
};
