import {
    LoginApi,
    LoginVariablesApi,
    LogoutApi,
    LogoutVariablesApi,
    useLoginApi,
    useLogoutApi,
} from 'graphql/generated';
import { canUseDom } from 'helpers/canUseDom';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { UseMutationState } from 'urql';
import { removeTokensFromCookies, setTokensToCookie } from 'utils/Auth/TokensFromCookies';
import { cleanWishlistCookie } from '../wishlist/useHandleWishlistCookie';
import { usePersistStore } from 'store/zustand/usePersistStore';
import { dispatchBroadcastChannel } from 'hooks/useBroadcastChannel';

type LoginHandler = (variables: LoginVariablesApi, rewriteUrl?: string) => Promise<void>;
type LogoutHandler = () => Promise<void>;

export const useAuth = (): [
    [UseMutationState<LoginApi, LoginVariablesApi>, LoginHandler],
    [UseMutationState<LogoutApi, LogoutVariablesApi>, LogoutHandler],
] => {
    const updateGeneralState = usePersistStore((s) => s.updateLoginLoadingState);
    const resetContactInformationState = usePersistStore((s) => s.resetContactInformationState);
    const resetUserInformationState = usePersistStore((s) => s.resetUserInformationState);
    const updateUserState = usePersistStore((s) => s.updateUserState);
    const [loginUseMutationResponse, login] = useLoginApi();
    const [logoutUseMutationResponse, logout] = useLogoutApi();

    const router = useRouter();

    const loginHandler = useCallback<LoginHandler>(
        async (variables, rewriteUrl) => {
            const loginResult = await login(variables);

            if (loginResult.error !== undefined) {
                return;
            }

            const accessToken = loginResult.data?.Login.accessToken;
            const refreshToken = loginResult.data?.Login.refreshToken;

            if (accessToken !== undefined && refreshToken !== undefined) {
                updateUserState({ cartUuid: null, demandCartUuid: null });
                updateGeneralState({ loginLoading: 'loading' });
                setTokensToCookie(accessToken, refreshToken);
                resetContactInformationState();
                resetUserInformationState();
                dispatchBroadcastChannel({ name: 'reloadPage' });

                if (canUseDom()) {
                    window.location.href = rewriteUrl ?? router.asPath;
                }
            }
        },
        [
            login,
            resetContactInformationState,
            resetUserInformationState,
            router.asPath,
            updateGeneralState,
            updateUserState,
        ],
    );

    const logoutHandler = useCallback<LogoutHandler>(async () => {
        const logoutResult = await logout({});

        if (logoutResult.data?.Logout) {
            removeTokensFromCookies();
            cleanWishlistCookie();
            updateUserState({ wishlistUuid: null });
            updateUserState({ isLogoutLoading: true, cartUuid: null, demandCartUuid: null });
            resetContactInformationState();
            resetUserInformationState();
            dispatchBroadcastChannel({ name: 'reloadPage' });

            if (canUseDom()) {
                router.reload();
            }
        }
    }, [logout, resetContactInformationState, resetUserInformationState, router, updateUserState]);

    return [
        [loginUseMutationResponse, loginHandler],
        [logoutUseMutationResponse, logoutHandler],
    ];
};
