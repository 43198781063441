import { handleCartModifications, useCurrentCart } from 'connectors/cart/Cart';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { usePersistStore } from 'store/zustand/usePersistStore';
import { getCookies } from 'cookies-next';
import { useBroadcastChannel } from 'hooks/useBroadcastChannel';
import { useCurrentMinimalCart } from 'connectors/cart/MinimalCart';

export const useReloadCart = (isDemandCart = false): void => {
    const t = useTypedTranslationFunction();
    const { modifications } = useCurrentMinimalCart(isDemandCart);
    const { fetchCart } = useCurrentCart(false, isDemandCart);
    const router = useRouter();
    const { isUserLoggedIn } = useCurrentUserData();
    const { cartUuid, demandCartUuid } = usePersistStore((s) => s);
    const cartUuidValue = isDemandCart ? demandCartUuid : cartUuid;

    useBroadcastChannel({
        name: 'refetchCart',
        callBack: () => {
            const cookies = getCookies();
            const isWithUserTokens = !!(cookies.accessToken && cookies.refreshToken);

            if ((isUserLoggedIn && !isWithUserTokens) || (!isUserLoggedIn && isWithUserTokens)) {
                router.reload();
            } else if (cartUuidValue !== null || isUserLoggedIn) {
                fetchCart();
            }
        },
    });

    useEffect(() => {
        if (modifications !== null) {
            handleCartModifications(modifications, t);
        }
    }, [modifications, t]);
};
