import { Button } from 'components/Forms/Button/Button';
import Image from 'components/Basic/Image/Image';
import { Webline } from 'components/Layout/Webline/Webline';
import { useNotificationBars } from 'connectors/notificationBars/NotificationBars';
import { useAuth } from 'hooks/auth/UseAuth';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import decode from 'jwt-decode';
import Trans from 'next-translate/Trans';
import { FC, useEffect, useState } from 'react';
import { usePersistStore } from 'store/zustand/usePersistStore';
import { NotificationBarsType } from 'types/notificationBars';
import tinycolor from 'tinycolor2';
import { getCookie } from 'cookies-next';

export const NotificationBars: FC = () => {
    const notificationBarItems = useNotificationBars();
    const { isUserLoggedIn, user } = useCurrentUserData();
    const [isAdminLoggedInAsUser, setIsAdminLoggedAsUser] = useState(false);
    const updateUserState = usePersistStore((s) => s.updateUserState);
    const [, [, logout]] = useAuth();

    useEffect(() => {
        try {
            const accessToken = getCookie('accessToken');
            if (!accessToken) {
                return;
            }
            const decodedAccessToken = decode<Record<string, any>>(accessToken.toString());

            if ('cartUuid' in decodedAccessToken && decodedAccessToken.cartUuid.toString() !== '') {
                const cartUuid = decodedAccessToken.cartUuid.toString();

                updateUserState({ cartUuid: cartUuid === '00000000-0000-0000-0000-000000000000' ? null : cartUuid });
            }

            if ('administratorUuid' in decodedAccessToken && decodedAccessToken.administratorUuid !== null) {
                setIsAdminLoggedAsUser(true);
                return;
            }
            setIsAdminLoggedAsUser(false);
        } catch (e) {
            setIsAdminLoggedAsUser(false);
        }
    }, [isUserLoggedIn, updateUserState]);

    const extendByAdminLoggedInAsUserNotificationBar = (
        mappedNotificationBarItems: NotificationBarsType[],
        shouldExtend: boolean,
    ) => {
        if (shouldExtend) {
            mappedNotificationBarItems.push({
                text: (
                    <Trans
                        i18nKey="adminLoggedInAsCustomerWarning"
                        defaultTrans="Warning! You are logged in as a customer with the email {{ email }} <button>Log out</button>"
                        values={{ email: user?.email }}
                        components={{
                            button: (
                                <Button
                                    type="button"
                                    size="small"
                                    variant="secondary"
                                    className="ml-[10px]"
                                    onClick={logout}
                                ></Button>
                            ),
                        }}
                    />
                ),
                rgbColor: '#f12626',
                image: null,
            });
        }

        return mappedNotificationBarItems;
    };

    return (
        <>
            {extendByAdminLoggedInAsUserNotificationBar(notificationBarItems, isAdminLoggedInAsUser).map(
                (item, index) => {
                    return (
                        <div
                            className="py-[6px]"
                            style={{
                                backgroundColor: item.rgbColor,
                                color: tinycolor(item.rgbColor).isLight() ? '#08131f' : '#fff',
                            }}
                            key={index}
                        >
                            <Webline>
                                <div className="flex items-center justify-center text-center text-littleSmall font-bold leading-6">
                                    {item.image !== null && (
                                        <div className="mr-[10px]">
                                            <Image
                                                src={item.image.url}
                                                alt={`notification-image-${index}`}
                                                height={20}
                                                width={20}
                                            />
                                        </div>
                                    )}
                                    {typeof item.text === 'string' ? (
                                        <div dangerouslySetInnerHTML={{ __html: item.text }} />
                                    ) : (
                                        item.text
                                    )}
                                </div>
                            </Webline>
                        </div>
                    );
                },
            )}
        </>
    );
};

NotificationBars.displayName = 'NotificationBars';
