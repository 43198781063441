import { getCookies, hasCookie } from 'cookies-next';
import { AddToCartDataType } from 'types/cart';
import { StateCreator } from 'zustand';

type LoginLoadingStatus = 'not-loading' | 'loading' | 'loading-with-cart-modifications';

export type UserSlice = {
    canAccessCatalogOrderConfirmation: boolean;
    cartUuid: string | null;
    demandCartUuid: string | null;
    compareProducts: string[];
    wishlistUuid: string | null;
    lastVisitedProducts: string[];
    loginLoading: LoginLoadingStatus;
    isLogoutLoading: boolean;
    billingAddressActiveUuid: string;
    addToCartPopupData: AddToCartDataType | null;
    addToCartFetching: boolean;
    ipAddress: string;

    updateUserState: (value: Partial<UserSlice>) => void;
};

export const createUserSlice: StateCreator<UserSlice> = (set) => ({
    canAccessCatalogOrderConfirmation: false,
    cartUuid: null,
    demandCartUuid: null,
    compareProducts: [],
    lastVisitedProducts: [],
    wishlistUuid: hasCookie('wishlist') ? getCookies().wishlist ?? null : null,
    loginLoading: 'not-loading',
    isLogoutLoading: false,
    billingAddressActiveUuid: '',
    addToCartPopupData: null,
    addToCartFetching: false,
    ipAddress: '',

    updateUserState: (value) => {
        set(value);
    },
});
